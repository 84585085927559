import { createReducer } from '@reduxjs/toolkit';
import {
  CREATE_CHECKLIST_FOR_TASKS_SUCCESS,
  CREATE_CHECKLIST_FOR_TASKS_FAILURE,
  GET_CHECKLIST_FOR_TASKS_SUCCESS,
  DELETE_CHECKLIST_FOR_TASKS_SUCCESS,
  DELETE_CHECKLIST_FOR_TASKS_FAILURE,
  ADD_CHECKITEM_SUCCESS,
  ADD_CHECKITEM_FAILURE,
  DELETE_CHECKITEM_SUCCESS,
  DELETE_CHECKITEM_FAILURE,
} from 'Redux/actions/tasksCheckList';
import { CheckItem, CheckList } from 'types/checklist';

interface CheckListState {
  checklist: CheckList[];
  error: any;
}

const initialState: CheckListState = {
  checklist: [],
  error: null,
};

const handlers = {
  [GET_CHECKLIST_FOR_TASKS_SUCCESS as any]: (prevState: CheckListState, { payload }: { payload: CheckList[] }) => {
    return {
      ...prevState,
      checklist: payload,
    };
  },

  [CREATE_CHECKLIST_FOR_TASKS_SUCCESS as any]: (prevState: CheckListState, { payload }: { payload: CheckList }) => {
    return {
      ...prevState,
      checklist: [...prevState.checklist, payload],
    };
  },
  [CREATE_CHECKLIST_FOR_TASKS_FAILURE as any]: (prevState: CheckListState, { payload }: { payload: any }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
  [DELETE_CHECKLIST_FOR_TASKS_SUCCESS as any]: (prevState: CheckListState, { payload }: { payload: CheckList[] }) => {
    return {
      ...prevState,
      checklist: payload,
    };
  },
  [DELETE_CHECKLIST_FOR_TASKS_FAILURE as any]: (prevState: CheckListState, { payload }: { payload: any }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [ADD_CHECKITEM_SUCCESS as any]: (
    prevState: CheckListState,
    { payload }: { payload: { checklistId: string; newCheckItem: CheckItem } },
  ) => {
    const updatedChecklist = prevState.checklist.map((list) => {
      if (list._id === payload.checklistId) {
        return {
          ...list,
          checkItems: [...list.checkItems, payload.newCheckItem],
        };
      }
      return list;
    });

    return {
      ...prevState,
      checklist: updatedChecklist,
    };
  },

  [ADD_CHECKITEM_FAILURE as any]: (prevState: CheckListState, { payload }: any) => ({
    ...prevState,
    error: payload.message,
  }),

  [DELETE_CHECKITEM_SUCCESS as any]: (
    prevState: CheckListState,
    { payload }: { payload: { checklistId: string; checkItemId: string } },
  ) => {
    const updatedChecklist = prevState.checklist.map((list) => {
      if (list._id === payload.checklistId) {
        return {
          ...list,
          checkItems: list.checkItems.filter((item) => item._id !== payload.checkItemId),
        };
      }
      return list;
    });
    return {
      ...prevState,
      checklist: updatedChecklist,
    };
  },
  [DELETE_CHECKITEM_FAILURE as any]: (prevState: CheckListState, { payload }: any) => ({
    ...prevState,
    error: payload.message,
  }),
};

export default createReducer(initialState, handlers);
