import { takeLatest } from 'redux-saga/effects';

import {
  CREATE_PROJECT_TASK_SUCCESS,
  CREATE_TASK_POSITIONS_SUCCESS,
  CREATE_TASK_WITH_TIMELOG_SUCCESS,
  DELETE_PROJECT_TASK_FAILURE,
  DELETE_PROJECT_TASK_SUCCESS,
  EDIT_PROJECT_TASK_SUCCESS,
} from '../../../actions/projectTasks';
import onCreateProjectTask from './onCreateProjectTask';
import onDeleteProjectTask from './onDeleteProjectTask';
import setErrorOnFailure from './setErrorOnFailure';
import onEditProjectTask from './onEditProjectTask';
import onCreateTaskPositions from './onCreateTaskPositions';

export default function* fn() {
  yield takeLatest(CREATE_PROJECT_TASK_SUCCESS, onCreateProjectTask);
  yield takeLatest(DELETE_PROJECT_TASK_SUCCESS, onDeleteProjectTask);
  yield takeLatest(DELETE_PROJECT_TASK_FAILURE, setErrorOnFailure);
  yield takeLatest(EDIT_PROJECT_TASK_SUCCESS, onEditProjectTask);
  yield takeLatest(CREATE_TASK_POSITIONS_SUCCESS, onCreateTaskPositions);
}
