export const en = {
  translation: {
    reportSave: 'Save report',
    total: 'Total',
    team: 'Team',
    selectUser: 'Select a user',
    selectProject: 'Select a project',
    searchByName: 'Search by name',

    year_one: 'year',
    year_two: 'years',
    year_other: 'years',

    yes: 'YES',
    toastPasswordText: 'The Password was changed!',
    toastInfoText: 'The Info was changed!',
    date: 'Date',
    startDateVacancies: 'Start Date',
    endDateVacancies: 'End Date',
    typographyActionTextResetPassword: 'Forgot a password?',
    typographyActionLinkResetPassword: 'Reset password',
    typographyActionPasswordDidntMatch: "The two password fields didn't match",
    expiredLinkTitle: 'Your link is expired',
    expiredLinkText: 'Please try to reset password again',
    profileLabel: 'Profile',
    profileMyLabel: 'My profile',
    profileUserLabel: 'profile',
    logoutLabel: 'Logout',

    userTableFullName: 'Full Name',
    userTableRole: 'Role',
    userTableWorkingHours: 'Working hours',
    userTableBillableHours: 'Billable hours',
    userProjectName: 'Project name',
    userTableNonBillableHours: 'Non-billable hours',
    userTableVacation: 'Vacation',
    userTableBench: 'Bench',
    userTableDayOff: 'Day-off',
    userTableSickDay: 'Sick day',
    userTableBenchPercentage: 'Bench percentage (%)',
    userTableEmail: 'OmiSoft Email',
    userTableStatus: 'Status',
    userTableSalary: 'Salary',
    userTableRate: 'Rate',
    userTableMinRate: 'Min-rate',
    userTableMinSalary: 'Min-salary',
    userTableMaxRate: 'Max-rate',
    userTableMaxSalary: 'Max-salary',
    userTableGrade: 'Grade',
    userTableSpecialization: 'Specialization',
    userTableEnglishLevel: 'English level',

    candidateHistoryAuthor: 'Author',
    candidateHistoryOldStatus: 'Old Status',
    candidateHistoryNewStatus: 'New Status',
    candidateHistoryDate: 'Date',

    commentsTableTitle: 'Title',
    commentsTableComment: 'Comment',
    commentsTableDate: 'Date',

    trackingPageTitle: 'Time Tracking',
    vacationsPageTitle: 'Absence Requests',

    timelogTitle: 'Project Name / Task Name',
    timelogUsername: 'User Name',
    timelogComment: 'Comment',
    timelogDate: 'Date',
    timelogStatus: 'Timelog',

    timelogPageTitle: 'Time Tracking',
    timelogBtnAdd: 'Add Timelog',
    timelogModalAddTitle: 'Add Timelog',
    timelogModalEditTitle: 'Edit Timelog',
    timelogModalProject: 'Select Project',
    timelogModalTask: 'Task',
    timelogModalComment: 'Comment',
    timelogModalDate: 'Date',
    timelogModalBtnCancel: 'Cancel',
    timelogModalBtnEdit: 'Update Timelog',
    timelogModalBtnDelete: 'Delete Timelog',
    timelogModalProjectLabel: 'Project',
    timelogModalTaskLabel: 'Select task',
    timelogModalHourLabel: 'Hours',
    timelogModalMinutesLabel: 'Minutes',
    timelogMO: 'MO',
    timelogTU: 'TU',
    timelogWE: 'WE',
    timelogTH: 'TH',
    timelogFR: 'FR',
    timelogSA: 'SA',
    timelogSU: 'SU',

    projectsBtnAdd: 'Add Project',
    projectsModalAddTitle: 'Add Project',
    projectsModalEditTitle: 'Edit Project',
    projectsModalBtnCancel: 'Cancel',
    projectsModalBtnDelete: 'Delete Project',
    projectsModalBtnSave: 'Save Changes',
    projectsModalName: 'Name',
    projectsModalCustomer: 'Customer',
    projectsModalDate: 'Start date',
    projectsModalSwitcher: 'Is project active?',
    projectIsBillableSwitch: 'Is project billable?',
    projectsDeleteConfirmTitle: 'You are sure you want to delete this project?',
    projectsDeleteConfirmBtnCancel: 'Cancel',
    projectsDeleteConfirmBtnDelete: 'Delete',
    projectsTabsBacklog: 'Backlog',
    projectsTabsBoard: 'Board',
    projectsTabsMilestones: 'Milestones',
    projectsTabsSprints: 'Sprints',
    projectsTabsVersions: 'Versions',
    meetingsPageTitle: 'Stand-up meetings',
    projectsPageTitle: 'Projects',
    dashboardPageTitle: 'Dashboard',
    dashboardStatisticsPageTitle: 'Statistics dashboard',

    nonTrimmedField: 'Field should not contain spaces',
    nonValidEmail: 'Please enter a valid email',
    passwordsDontMatch: "The two password fields didn't match",
    requiredField: 'This field is required',
    tooShortFirstName: 'First Name is too short',
    tooShortLastName: 'Last Name is too short',
    greaterThanField: 'Number should be greater than 0',
    minValueWorkingHours: 'Minimal working hours value is 0',
    maxValueWorkingHours: 'Maximal working hours value is 24',
    integerField: 'Number should be integer',
    correctCodeField: 'Enter correct code',
    zeroTimeError: "Total time can't be 0!",

    basicInfoTab: 'Basic info',
    locationTab: 'Location',
    contactsTab: 'Contacts',
    financialInfoTab: 'Financial info',
    securityTab: 'Security',
    historyTab: 'History',
    requestsTab: 'Under Review',
    vacationsPerYear: 'Statistics',
    vacationsTab: 'All Requests',
    myVacationsTab: 'My Requests',

    createUserTitle: 'Create an user',
    createCandidateTitle: 'Create a candidate',
    createCandidateSuccessMsg: 'Candidate has been successfully created',
    createCandidateErrorMsg: 'Something went wrong',
    editUserTitle: 'Edit an user',
    editCandidateTitle: 'Edit a candidate',
    basicInfoTitle: 'Basic info',
    locationTitle: 'Location',
    contactInfoTitle: 'Contact information',
    financialInfoTitle: 'Financial information',
    hrInfoTitle: 'HR information',
    signInTitle: 'Sign in',
    resetPasswordTitle: 'Reset password',
    resetPasswordDialogTitle: 'A new password has been sent to your email',
    resetPasswordOneTimeLinkTitle: 'The link to change the password has been sent to your email',
    resetPasswordSuccess: 'Password has been changed successfully',

    docBookMembersTitle: 'Members',
    docBookAdminsTitle: 'Editors',
    createNewDocTitle: 'Create Page',
    editNewDocTitle: 'Edit Page',

    firstNameLatinField: 'First Name (Latin)',
    lastNameLatinField: 'Last Name (Latin)',
    firstNameCyrillicField: 'First Name (Cyrillic)',
    lastNameCyrillicField: 'Last Name (Cyrillic)',
    fullNameCyrillicField: 'Full Name (Cyrillic)',
    aboutField: 'About me',
    birthdayField: 'Birthday',
    emailField: 'Email',
    omiEmailField: 'OmiSoft email',
    mobilePhoneField: 'Mobile Number',
    telegramField: 'Telegram',
    passwordField: 'Password',
    oldPasswordField: 'Old password',
    newPasswordField: 'New password',
    confirmPasswordField: 'Confirm password again',
    projectNameField: 'Project name',
    reasonForDismissal: 'Reason for dismissal',
    projectCustomerField: 'Customer name',
    projectIsActiveField: 'Show deactivated projects',
    weekField: 'Week',
    monthField: 'Month',
    yearField: 'Year',
    anotherField: 'Custom interval',
    usersIsActiveField: 'Show deactivated users',
    specializationField: 'Specialization',
    developerGradeField: 'Grade',
    techStackField: 'Tech stack',
    englishLevelField: 'English level',
    englishField: 'English',
    roleField: 'Role',
    candidateStatusField: 'Candidates status',
    openVacanciesField: 'Open vacancies',
    chosenVacancy: 'Vacancy',
    workingHoursField: 'Working hours',
    trelloCardLinkField: 'Trello link',
    cvLinkField: 'CV link',
    otherLinksField: 'Other links',
    countryField: 'Country',
    cityField: 'City',
    novaPoshtaAddressField: 'Nova poshta adress',
    skypeField: 'Skype',
    upworkLinkField: 'UpWork link',
    gitHubField: 'GitHub username',
    gitLabField: 'GitLab username',
    linkedInField: 'LinkedIn',
    fopField: 'Are you FOP? (Private entrepreneur)',
    payoneerEmailField: 'Payoneer email',
    monobankCardField: 'Monobank card',
    privatBankCardField: 'Privat bank card',
    ipnField: 'Individual tax number',
    dateOfStartingField: 'Date of starting',
    dateOfBirthday: 'Date of Birthday',
    channelField: 'Channel (djinni, dou...)',
    minSalaryField: 'Minimum salary',
    maxSalaryField: 'Maximum salary',
    minRateField: 'Minimum rate',
    maxRateField: 'Maximum rate',
    hiringCommissionField: 'Hiring commission',
    commissionReceiverField: 'Commission receiver',
    holidayNameLatin: 'Name (latin)',
    holidayNameCyrillic: 'Name (cyrillic)',
    holidayDate: 'Holiday date',
    newDocPageTitle: 'Title',
    newDocPageTags: 'Tags',
    titleField: 'Title',
    contentField: 'Description',
    bookStatusField: 'Book visibility',
    historySourceField: 'Source',
    historyTargetField: 'Target',

    columnName: 'Column Name',
    columnStatus: 'Status',
    otherColumn: 'Other Column',
    addColumn: 'Add Column',
    columnNameAlreadyExists: 'Hold up, already here!',
    manageProjectColumnButton: 'Manage Columns',
    editColumnsModalTitle: 'Edit columns',
    confirmDeleteColumnTitle: 'Are you sure you want to delete the column?',
    columnAddSuccessMsg: 'Column has been successfully added',
    columnUpdateSuccessMsg: 'Column has been successfully updated',
    columnDeleteSuccessMsg: 'Column has been successfully deleted',
    chooseColumnForDeletedColumnTasksTitle: 'Choose column for tasks replacement',

    okButton: 'OK',
    saveButton: 'Save',
    editButton: 'Edit',
    editProjectColumnsButton: 'Edit columns',
    editProjectButton: 'Edit project',
    editProfileButton: 'Edit profile',
    avatarSelectMenu: 'You can select avatar',
    avatarSelectButton: 'Upload avatar',
    sendButton: 'Send',
    deleteButton: 'Delete',
    cancelButton: 'Cancel',
    cancelChangesButton: 'Cancel changes',
    filtersButton: 'Filters',
    membersButton: 'Members',
    adminsButton: 'Editors',
    confirmButton: 'Confirm',
    resetButton: 'Reset',
    generatePasswordButton: 'Generate new password',
    createUserButton: 'Create user',
    createCandidateButton: 'Create candidate',
    updateUserButton: 'Update user',
    updateCandidateButton: 'Update',
    closeUserInfoButton: 'Close info',
    goToButton: 'Go',
    addHolidayButton: 'Add holiday',
    deleteHolidayButton: 'Delete holiday',
    saveChangesButton: 'Save changes',
    savePasswordButton: 'Save password',
    submitBtn: 'Submit',
    approveButton: 'Approve',
    declineButton: 'Decline',
    createDocPageBtn: 'Create Page',
    createDocBook: 'Create Book',
    editDocBook: 'Edit Book',
    activateButton: 'Activate',
    deactivateButton: 'Deactivate',
    activateProjectButton: 'Activate project',
    activatedProject: 'Project is active. Click to deactivate',
    deactivatedProject: 'Project is deactiveted. Click to activate',
    addToSlack: 'Add to Slack',
    connectedToSlack: 'Connected to Slack',
    activatedMsg: 'Active',
    deactivatedMsg: '(deactivated)',
    submitFridayStatusTitle: 'Have you tracked your week?',
    submitFridayStatus: "Yes, I've tracked my week",
    denyFridayStatus: 'No, later',
    deleteBook: 'Delete book',
    archivePage: 'Archive page',
    unarchivePage: 'Unarchive page',
    searchUsers: 'Search users',
    searchProject: 'Search a project',
    searchTitle: 'Search',
    globalSearch: 'Global search',
    teamSearch: 'Team search',
    assignToLabel: 'Assign to',
    assignedToLabel: 'Assigned to',
    notAssigned: 'Not assigned',
    priorityLabel: 'Priority',
    goTocomments: 'Go to comments',
    isFired: 'Is fired',
    candidatesAttribute: 'Add attribute',
    hrInterview: 'HR Interview',
    checkedEnglishLevel: 'Checked English level',
    needPing: 'Need ping',
    testTaskIsDone: 'Test task is done',
    partTime: 'Part time',
    fullTime: 'Full time',
    raisingStar: 'Raising star',

    createVacationButton: 'Create Request',
    selectReviewers: 'Select reviewers',
    requestVacation: 'Add Request',
    addVacation: 'Create',
    editVacationRequest: 'Edit a vacation request',
    vacationRequestAdd: 'Vacation request has been successfully added',
    vacationRequestsFailure: 'Vacation request added failure',
    vacationConfirmationMainMessage: 'Are you sure that you want to create a {{type}} for {{dayNumber}} days? ',
    vacationRequestChange: 'Vacation request has been changed',
    createVacationSuccess: 'Vacancy has been successfully created',
    vacationReviewersChange: 'Vacation reviewers have been changed',
    vacationDeleteMessage: 'The vacation request has been deleted',
    vacationStatusChange: 'Status of vacation request has been changed',
    vacationTypeSick: 'Sick',
    vacationTypesDayOff: 'Unpaid Day Off',
    vacationTypesVacation: 'Vacation',
    vacationTypesVacationForConfirm: 'Vacation',
    reviewers: 'Reviewers',
    pendingStatus: 'Pending',
    approvedStatus: 'Approved',
    declinedStatus: 'Declined',
    changeReviewers: 'Change reviewers',
    deleteVacationTitle: 'Are you sure you want to delete a vacation request?',
    changeVacationStatusApprove: 'Approve vacation request?',
    changeVacationStatusDecline: 'Decline vacation request?',
    vacationToReview: 'Please review a vacation',
    errorInvalidDate: 'End date must be bigger than start date',
    errorExceedingDateInterval: 'Date interval cannot be more than 3 month',
    allVacations: 'All vacations',
    futureVacations: 'Upcoming Requests',

    labelYes: 'Yes',
    labelNo: 'No',
    labelFire: 'Fire',
    labelCancel: 'Cancel',
    labelStartDate: 'Start date',
    labelEndDate: 'End date',
    labelYear: 'Select year',
    labelMonth: 'Select month',
    labelUserName: 'User Name',
    labelPeriod: 'Period',
    labelComment: 'Comment',
    labelType: 'Type',
    statusLabel: 'Status',
    totalDays: 'Total days',
    requestsLabel: 'Requests',

    reportDeleteConfirmTitle: 'You are sure you want to delete this report?',
    reportDeleteConfirmBtnCancel: 'Cancel',
    reportDeleteConfirmBtnDelete: 'Delete',

    dashboardSection: 'Dashboard',
    meetingsSection: 'Stand-up',
    projectsSection: 'Projects',
    teamSection: 'Team',
    billabilityReportsSection: 'Billability Reports',
    benchReportsSection: 'Bench Reports',
    candidatsSection: 'Candidates',
    timeTrackingSection: 'Time Tracking',
    RecruitmentHRSection: 'Recruitment & HR',
    reportsSection: 'Reports',
    timeTrackingReportSection: 'Time Tracking Report',
    wikiSection: 'Wiki',
    vacationsSection: 'Absence Requests',
    remindersSection: 'Reminders',
    vacanciesSection: 'Vacancies',
    hrStatisticsSection: 'HR statistics',

    projectNameCell: 'Project Name',
    customerNameCell: 'Customer Name',
    startDateCell: 'Start Date',
    billableCell: 'Billable',
    billable: 'Billable',
    notBillable: 'Not billable',
    projectTasksTitleCell: 'Title',
    projectTasksDescriptionCell: 'Description',
    projectTasksBudgetCell: 'Budget',
    projectTasksStatusCell: 'Status',
    projectTasksTrackedTime: 'Tracked time',
    projectTasksBillableCell: 'Billable',
    projectTasksDeleteComment: 'Are you sure you want to delete the comment?',
    projectErrorDeleteTask: 'You cannot delete this ticket because it has logged hours',
    commenHasBeenEdited: 'The comment has been edited',
    editComment: 'Edit a comment...',
    newCommentCreated: 'A new comment has been created',
    commentUpdate: 'Updated',
    commentDeleted: 'Comment deleted',
    addAComment: 'Add a comment...',
    projectTaskStatus: 'Status',
    projectTaskPriority: 'Priority',
    projectTaskExecutor: 'Executor',
    taskHistoryEventCommentExecutorTitle: 'executor',
    projectTaskExecutorDelete: 'Unassigned',
    projectTaskAuthor: 'Author',
    projectTaskStatusBacklog: 'Backlog',
    projectTaskStatusTodo: 'To-do',
    projectTaskStatusInProgress: 'In progress',
    projectTaskStatusTesting: 'Testing',
    projectTaskStatusDone: 'Done',
    projectTaskStatusFailed: 'Failed',
    projectTaskStatusAll: 'All',
    projectTaskStatusCurrent: 'Current',
    projectSprintStatusNew: 'New',
    projectSprintStatusCurrent: 'Current',
    projectSprintStatusPast: 'Past',
    allSprints: 'All (without "Past")',
    projectVersionStatusReleased: 'Released',
    projectVersionStatusFuture: 'Future',
    projectVersionStatusInDevelop: ' In develop',
    projectVersionStatusArchive: 'Archived',
    allVersions: 'All (without "Archived")',

    newChecklistCreated: 'A new checklist has been created',
    ChecklistDeleted: 'Checklist deleted',
    newCheckItemCreated:'A new checkitem has been created',
    newCheckItemDeleted:'Checkitem deleted',

    taskSpecialTypeTitle: 'Special type',
    taskSpecialTypeDelete: 'Delete special type',
    taskSpecialTypeSickDay: 'Sick Day',
    taskSpecialTypeDayOff: 'Day-Off',
    taskSpecialTypeVacation: 'Vacation',
    taskSpecialTypePreSale: 'Pre-Sale',
    taskSpecialTypeBench: 'Bench',
    taskSpecialTypeInterviews: 'Interviews',

    taskPriorityLow: 'Low',
    taskPriorityMedium: 'Medium',
    taskPriorityHigh: 'High',
    taskPriorityCritical: 'Critical',
    deleteTaskPriority: 'Delete priority',

    newBacklogButton: 'Add backlog task',

    newProjectTaskTitle: 'Add new task',
    editProjectTaskTitle: 'Edit task',
    addProjectTaskButton: 'Add task',
    editProjectTaskButton: 'Edit task',
    addProjectMilestoneButton: 'Add milestone',
    editProjectMilestoneButton: 'Edit milestone',
    sprintTitle: 'Sprint',
    addProjectSprintButton: 'Add sprint',
    editProjectSprintButton: 'Edit sprint',
    changeProjectSprintStatus: 'Change status',
    editSprintConfirmationText:
      'Are you sure you want to make this sprint as current? Pay attention that the status of existing current sprint will be automatically changed to "past".',
    versionTitle: 'Version',
    taskHistoryEventCommentVersionTitle: 'version',
    addProjectVersionButton: 'Add version',
    editProjectVersionButton: 'Edit version',
    changeProjectVersionStatus: 'Change status',
    statusFilter: 'Status',
    nameFilter: 'Name',

    projectCodeTitle: 'Project code',
    maxNumberOfSymbols: '10 characters maximum',
    upperCaseOnly: 'The code must contain only uppercase characters and numbers',
    withoutSpaceOnly: 'The code should not contain spaces',

    projectTaskTitle: 'Title',
    projectTaskDescription: 'Description',
    projectAddTaskDescription: 'Add description',
    projectTaskBudget: 'Budget (Hours)',
    projectTaskBillable: 'Billable task',
    projectTaskTitleOrDescription: 'Title or description',
    projectTaskActivity: 'Activity',
    projectActivityComments: 'Comments',
    projectsActivityHistory: 'History',
    projectsActivityWorklogs: 'Work Log',
    createTaskText: 'created this task',
    chooseValueText: 'chose',
    removeValueText: 'removed',
    changeValueText: 'changed',
    forTask: 'for this task',
    fromTask: 'from this task',
    ofTaskFrom: 'of this task from',
    to: 'to',
    at: 'at',
    setTaskToBillable: 'setted this task as billable',
    setTaskToUnbillable: 'setted this task as unbillable',
    showButtons: 'Show : ',
    loadMore: 'Load More',
    noHistoryEvents: 'No history events for this task',

    projectMilestoneName: 'Name',
    projectMilestoneTaskName: 'Task',
    projectMilestoneDescription: 'Description',
    projectMilestoneStartDate: 'Start date',
    projectMilestoneEndDate: 'End date',
    period: 'Period',
    projectMilestoneStatus: 'Status',
    projectSprintNumber: 'Number',
    projectSprintTaskName: 'Task',
    projectSprintDescription: 'Description',
    projectSprintStartDate: 'Start date',
    projectSprintEndDate: 'End date',
    projectSprintStatus: 'Status',
    projectSprintChangeStatusBtn: 'Change sprint status',
    deleteFromSprintBtn: 'Delete from sprint',
    noSprints: 'No sprints',
    projectVersionName: 'Name',
    projectVersionTaskName: 'Task',
    projectVersionDescription: 'Description',
    projectVersionStartDate: 'Start date',
    projectVersionReleaseDate: 'Release date',
    projectVersionStatus: 'Status',
    projectVacanciesStatus: 'Status',
    projectVersionChangeStatusBtn: 'Change version status',
    deleteFromVersionBtn: 'Delete from version',
    noVersions: 'No versions',

    deleteMilestoneModalTitle: 'Are you sure you want to delete this milestone?',
    addToMilestoneButton: 'Add to milestone',
    changeMilestoneButton: 'Select another milestone',
    deleteFromMilestoneBtn: 'Delete from milestone',
    noMilestones: 'No milestones',
    addToMilestoneTitle: 'Select milestone',
    milestoneTitle: 'Milestone',
    withoutMilestone: 'Without milestone',

    callRecapProjectComment: 'Call Recap',
    requirementsProjectComment: 'Requirements',
    designProjectComment: 'Design',
    estimationProjectComment: 'Estimation',
    taskManagementProjectComment: 'Task Management',
    otherProjectComment: 'Other',

    projectMembersTitle: 'Project members',
    projectAdminsTitle: 'Project admins',

    timeLogConfirmDelete: 'Delete time log',
    timeLogConfirmDeleteTitle: 'Are you sure you want to delete this time log?',
    timeLogEmptyProjects: 'You have no active projects',

    statsBoxWorkers: 'Employees',
    statsBoxContractors: 'Contractors',
    statsBoxProjects: 'My projects',
    statsBoxCandidates: 'Candidates',

    dashboardTasksTitle: 'Tasks',
    dashboardHolidaysTitle: 'National holidays',
    dashboardChartTitle: "Team's working hours",
    dashboardBirthdaysTitle: 'Birthdays',
    dashboardTasksReport: 'Send a daily report',
    dashboardTasksTimeLog: 'Track the time for today',
    dashboardChartMyTitle: 'My working hours',
    dashboardTasksVacations: 'Absence Requests',
    dashboardVacationsTitle: 'This week vacations or days-off',
    dashboardAnniversaryTitle: 'Anniversary in the company',
    billableHoursLabel: 'Billable hours',
    nonBillableHoursLabel: 'Non-billable hours',

    addHolidayTitle: 'Add holiday',
    editHolidayTitle: 'Edit holiday',
    holidayConfirmDelete: 'Are you sure you want to delete this holiday?',

    filterByRole: 'By role',
    filterByStatus: 'By status',
    filterByName: 'By Full name',
    filterBySpecialization: 'By Specialization',
    userActiveStatus: 'User active status',
    filterByGrade: 'By grade',
    filterByAddRole: 'By Additional Role',
    filterByVacancy: 'By vacancy',
    filterByVacancyStatus: 'By vacancy status',
    filterByCandidateAttribute: 'By attribute',
    filterByRefusalReason: 'By refusal reason',
    allStatusesFilter: 'All',
    filterByUser: 'User',
    filterAllCandidates: 'All candidates',
    startDateFilter: 'Start date',
    endDateFilter: 'End date',
    isBillableFilter: 'Billable tasks',
    milestoneFilter: 'Milestones',

    removeProjectFilter: 'Remove project filter',
    removeUserFilter: 'Remove user filter',
    removeFilter: 'Remove filter',
    removeVacancyCandidate: 'Remove vacancy',
    removeTaskSpecialTypeFilter: 'Remove special type',

    noDataWarning: 'No data',
    noProjectOrUserSelected: 'Please select project or user',
    noVacancySelected: 'Please select a vacancy or specialization',
    noCandidates: 'Candidates not found',

    allRoles: 'All Roles',
    ownerRole: 'Owner',
    leadRole: 'Lead',
    accountantRole: 'Accountant',
    adminRole: 'Admin',
    managerRole: 'Manager',
    recruiterRole: 'Recruiter',
    HRRole: 'HR',
    salesRole: 'Sales',
    employeeRole: 'Employee',
    contractorRole: 'Contractor',
    partnerRole: 'Partner',
    candidateRole: 'Candidate',
    roleArray: 'Employee, Manager, Lead',

    allStatuses: 'All statuses',
    newStatus: 'New request',
    preScreeningStatus: 'Pre-screening',
    onPendingStatus: 'Under consideration of Leads',
    hrInterviewStatus: 'HR interview',
    checkingEnglishLevelStatus: 'Checking English level',
    suitableStatus: 'Сandidate is suitable',
    approvedByLeadStatus: 'Approved by Lead',
    testStatus: 'Test task',
    techInterviewStatus: 'Technical interview',
    preSuitableStatus: 'Pre-suitable',
    customerInterviewStatus: 'Customer interview',
    notSuitableStatus: `Not suitable`,
    sentOfferStatus: 'Offer sent',
    acceptedOfferStatus: 'Offer accepted',
    onboardingStatus: 'Onboarding',
    inTeamStatus: 'Team member',
    blacklistStatus: 'In blacklist',
    needPingStatus: 'Need ping',
    contractorStatus: 'Contractor',
    doneStatus: 'Done',

    hrInterviewAttribute: 'HR Interview',
    englishLevelAttribute: 'Checking English level',
    needPingAttribute: 'Need Ping',
    testTaskAttribute: 'Test Task is done',
    partTimeAttribute: 'Part time',
    fullTimeAttribute: 'Full time',
    starAttribute: 'Raising star',

    meetingAddStatus: 'Add status',
    meetingEditStatus: 'Edit status',
    meetingDate: 'Meeting date',

    meetingCellName: 'User Name',
    meetingCellYesterday: 'What did you complete yesterday?',
    meetingCellToday: 'What do you commit to today?',
    meetingCellTodayDetail: 'What do you commit to today? (3 main tasks, top-down priority)',
    meetingCellBlockers: 'Any blockers in your way?',

    projectCreateSuccessMsg: 'Project has been successfully created',
    projectEditSuccessMsg: 'Project has been successfully updated',
    projectDeleteSuccessMsg: 'Project has been successfully deleted',
    projectMembersSuccessMsg: 'Project members have been successfully updated',
    projectAdminsSuccessMsg: 'Project admins have been successfully updated',
    timelogCreateSuccessMsg: 'Timelog has been successfully created',
    timelogUpdateSuccessMsg: 'Timelog has been successfully updated',
    timelogDeleteSuccessMsg: 'Timelog has been successfully deleted',
    timelogCreateFailureMsg: 'Timelog creation failure ',
    timelogUpdateFailureMsg: 'Timelog updating failure',
    timelogDeleteFailureMsg: 'Timelog deleting failure',
    warningTimeTrackingMessage:
      'Please log your off-clock hours in the day you were working less than your usual hours. For example, you worked 5 hours instead of 8 on Tuesday, and completed missing 3 off-clock hours on Saturday, then you should add 3 for Tuesday log. Please note, you can log only ',
    overtimeTitle: 'overtimes',
    overtimeInfoMessage: "Overtime is time worked in addition to one's normal working hours",
    timeTrackingInfoMessage: ' on weekend.',
    noTasksAvailableMsg: 'No tasks available',
    trackTimePenalty: 'Please track your time for today',
    dailyReportPenalty: 'Please submit a daily report',
    weekTimeTraked: 'Please validate and send your time for week',
    docsNotFound: 'Docs not found',
    pageNotFound: '404 Page Not Found',
    notFoundText: 'The page you are looking for does not exist',
    candidatesNotFound: 'Candidates not found',
    docBookMembersUpdatedMsg: 'Doc book members have been successfully updated',
    docBookAdminsUpdatedMsg: 'Doc book admins have been successfully updated',
    docPageCreateSuccessMsg: 'Doc page has been successfully created',
    docPageEditSuccessMsg: 'Doc page has been successfully updated',
    docPageArchiveSuccessMsg: 'Doc page has been successfully archived',
    docPageUnarchivedSuccessMsg: 'Doc page has been successfully unarchived',
    docBookCreateSuccessMsg: 'Book has been successfully created',
    docBookEditSuccessMsg: 'Book has been successfully updated',
    docBookChangeStatusSuccessMsg: 'the status of a book has been successfully changed',
    holidayCreateSuccessMsg: 'Holiday has been successfully created',
    holidayEditSuccessMsg: 'Holiday has been successfully updated',
    holidayDeleteSuccessMsg: 'Holiday has been successfully deleted',
    AddMeetingReportSuccessMsg: 'Daily status has been successfully created',
    EditMeetingReportSuccessMsg: 'Daily status has been successfully updated',
    DeleteMeetingReportSuccessMsg: 'Daily status has been successfully deleted',
    projectTaskAddSuccessMsg: 'Project task has been successfully created',
    projectTaskEditSuccessMsg: 'Project task has been successfully updated',
    projectTaskDeleteSuccessMsg: 'Project task has been successfully deleted',
    projectTasktrackTimeErrorMsg: 'You are not a project member so you cannot log hours',
    projectTasktrackTimeAnotherUserErrorMsg: 'You are not a project editor so you cannot log hours for another user',
    projectMilestoneAddSuccessMsg: 'Project milestone has been successfully created',
    projectMilestoneEditSuccessMsg: 'Project milestone has been successfully updated',
    projectMilestoneDeleteSuccessMsg: 'Project milestone has been successfully deleted',
    projectSprintAddSuccessMsg: 'Project sprint has been successfully created',
    projectSprintEditSuccessMsg: 'Project sprint has been successfully edited',
    projectSprintChangeStatusSuccess: 'Project sprint status has been successfully edited',
    projectVersionAddSuccessMsg: 'Project version has been successfully created',
    projectVersionEditSuccessMsg: 'Project version has been successfully edited',
    projectVersionChangeStatusSuccess: 'Project version status has been successfully edited',

    createUserSuccessMsg: 'User has been successfully created',
    editUserSuccessMsg: 'User has been successfully updated',
    editUserErrorMsg: 'User updating failed',
    changeUserStatusSuccessMsg: 'Users status has been successfully updated',
    changeUserStatusErrorMsg: 'Users status updating failed',
    changeUserEmailErrorMsg: 'User with this email already exists',
    docBookDeleteWarningMsg: 'Are you sure you want to delete this book?',
    docPageArchiveWarningMsg: 'Are you sure you want to archive this page?',
    docPageUnarchiveWarningMsg: 'Are you sure you want to unarchive this page?',
    cantSaveReportMsg: "No data available. Can't save report",
    specializationCreateSuccessMsg: 'Specialization has been successfully created',
    specializationUpdateSuccessMsg: 'Specialization has been successfully updated',
    specializationDeleteSuccessMsg: 'Specialization has been successfully deleted',
    channelCreateSuccessMsg: 'Channel has been successfully created',
    channelUpdateSuccessMsg: 'Channel has been successfully updated',
    channelDeleteSuccessMsg: 'Channel has been successfully deleted',
    addTaskForProject: 'Task successfully created',
    deletedTaskForProject: 'The task was successfully deleted',

    selectTaskRadio: 'Select task',
    createTaskRadio: 'Create new task',

    createTaskTitle: 'New task title',
    userTitle: 'User',

    trackTimeMenu: 'Track Time',
    addList: 'Add list',
    confirmDeleteList: 'Are you sure you want to delete this list?',
    add: 'Add',
    addItem: 'Add item',
    trackTimeForUserMenu: 'Track time for another user',
    deleteTaskMenu: 'Delete',
    showPageHistoryMenu: 'Page history',
    archiveMenu: 'Archive',
    unarchiveMenu: 'Unarchive',

    deleteModalTitle: 'Are you sure you want to delete this task?',
    deleteModalSubmit: 'Delete',
    deleteModalDeny: 'Cancel',
    dontSaveTaskModal: 'No',
    saveTaskModal: 'Yes',
    saveTaskChangesTitle: 'Do you want to save changes?',

    JanMonth: 'Jan',
    FebMonth: 'Feb',
    MarMonth: 'Mar',
    AprMonth: 'Apr',
    MayMonth: 'May',
    JunMonth: 'Jun',
    JulMonth: 'Jul',
    AugMonth: 'Aug',
    SepMonth: 'Sep',
    OctMonth: 'Oct',
    NovMonth: 'Nov',
    DecMonth: 'Dec',

    deactivatedUser: 'Deactivated',

    totalTimeCell: 'Total time',
    totalOvertimeCell: 'including overtime',
    goBackBtn: 'Go back',
    goBackBtnReset: 'Go back to',
    userDetailsTab: 'Details',
    userCommentsTab: 'Comments',
    userFinancialInfoTab: 'Financial info',
    userDocsAndLinksTab: 'Docs & Links',
    createCommentButton: 'Create comment',
    editCommentButton: 'Edit comment',

    candidateReviewUserComment: 'Candidate Review',
    englishTestUserComment: 'English Test',
    testTaskUserComment: 'Test task',
    interviewReviewUserComment: 'Interview review',
    refusalReasonUserComment: 'Refusal Reason',
    employeeDismissal: 'Employee dismissal',
    otherUserComment: 'Other',

    noExperienceRefusalReason: 'No working experience',
    lowEnglishRefusalReason: 'Low level of English',
    requiresHighSalaryRefusalReason: 'Requires overhigh salary',
    noContactRefusalReason: 'Lost contact',
    workInOfficeRefusalReason: 'Wants to work in office',
    interviewFailedRefusalReason: 'Failed interview',
    offerDeclinedRefusalReason: 'Has declined the offer',
    toxicRefusalReason: 'Toxic person',
    overratedOwnCapabilitiesReason: 'Overrated own capabilities ',

    userCommentEditSuccess: 'User comment has been successfully updated',
    userCommentCreateSuccess: 'User comment has been successfully created',
    commentTitleLabel: 'Comment title',
    refusalReasonLabel: 'Refusal reason',
    commentDescriptionLabel: 'Comment description',
    anonymouslyStatusLabel: 'Anonymously',
    userCommentDeleteTitle: 'Are you sure you want to delete comment?',
    userCommentDeleteSuccess: 'Comment has been successfully deleted',
    vacationComment: 'Comment',

    userReviewsTab: 'Reviews',
    anonymousAuthor: 'Anonymous author',
    suggestedToStartDoingTitle: 'What collegue should start doing',
    suggestedToStopDoingTitle: 'What collegue should stop doing',
    suggestedToContinueDoingTitle: 'What collegue is good at and should continue doing',
    userReviewDeleteTitle: 'Are you sure you want to delete a review?',
    createReviewButton: 'Create review',
    editReviewButton: 'Edit review',
    deleteReviewButton: 'Delete review',
    reviewCreateSuccess: 'The review has been created',
    reviewEditSuccess: 'The review has been edited',
    reviewDeleteSuccess: 'The review has been deleted',

    userRemindersTab: 'Reminders',
    createReminderLabel: 'Create reminder',
    editReminderLabel: 'Edit reminder',
    reminderAuthor: 'Author',
    reminderUser: 'User',
    reminderDate: 'Date',
    reminderDescription: 'Description',
    createReminderSuccess: 'The reminder has been created',
    editReminderSuccess: 'The reminder has been edited',
    deleteReminderSuccess: 'The reminder has been successfully deleted',
    deleteReminderTitle: 'Are you sure you want to delete the reminder?',
    remindersPenalty: "Check out today's reminders!",
    goToReminders: "Go to candidate's reminders",
    noReminders: 'no reminders',

    docBookPrivateStatus: 'Private',
    docBookPublicStatus: 'Public',
    allDocbooksTitle: 'All docbooks',
    activeDocbooksTitle: 'Active docbooks',
    archivedDocbooksTitle: 'Archived docbooks',
    docBookActivate: 'Activate',
    docBookArchivate: 'Archivate',
    docPageAllStatus: 'All pages',
    docPageActiveStatus: 'Active',
    docPageArchivedStatus: 'Archived',

    candidateChennel: 'Candidate channel',
    vacancyStatus: 'Vacancy status',
    vacancyTitle: 'Vacancy',
    vacancySpecialization: 'Specialization',
    additionRole: 'Addition Roles',
    vacancyGrade: 'Grade',
    vacancyDescription: 'About vacancy',
    vacancyStartDate: 'Start date',
    vacancyEndDate: 'End date',
    vacancyOpen: 'Open',
    vacancyEdit: 'Edit',
    vacancyTemplate: 'Template',
    vacancyCancelled: 'Cancelled',
    vacancyCloseSuccessful: 'Closed successfully',
    vacancyCloseUnSuccessful: 'Closed unsuccessfully',
    vacancyArchive: 'Archive',
    createVacancy: 'Create vacancy',
    createVacancyFromTemplate: 'Create vacancy from template',
    editVacancy: 'Edit vacancy',
    editStatusVacancy: 'Change vacancy status',
    openTheVacancy: 'Are you sure you want to open the vacancy?',
    closeTheVacancy: 'Are you sure you want to close the vacancy?',
    confirmOpenVacancy: 'Open',
    confirmCloseVacancy: 'Close',
    vacancyEditSuccess: 'Vacancy has been successfully updated!',
    vacancyStatusSuccess: 'Vacancy status has been successfully updated!',
    backToVacancies: 'Back to vacancies',
    allStatusesVacancies: 'All statuses',
    deleteVacancy: 'Delete vacancy',
    deleteVacancyConfirm: 'Are you sure you want delete this vacancy?',
    deleteVacancySuccess: 'Vacancy status has been successfully deleted!',
    chooseTemplate: 'Choose a template',
    vacancyDetailsTab: 'Details',
    vacancyCandidatesTab: 'Candidates',
    candidatesTab: 'Board',
    noVacancyCandidatesTab: 'List',
    noVacancyCandidates: 'Candidates without vacancy',
    vacancyFunnelOfCandidatesTab: 'Statistics',
    vacancyCandidatesNoData: 'There are no candidates for this vacancy yet.',
    noCandidateHistory: 'There are no history for this candidate yet.',
    goToVacancyButton: 'Go to Vacancy',

    createChannelTitle: 'Add channel',
    createChannelButton: 'Add',
    editChannelTitle: 'Edit channel',
    channelSelectTitle: 'Choose channel',
    editChannelButton: 'Edit',
    deleteChannelTitle: 'Delete channel',
    deleteChannelButton: 'Delete',
    deleteChannelConfirm: 'Choose channel which you want to delete.',
    channelName: 'Channel',
    newChannelName: 'New channel',

    createSpecializationTitle: 'Add specialization',
    createSpecializationButton: 'Add',
    editSpecializationTitle: 'Edit specialization',
    specializationSelectTitle: 'Choose specialization',
    editSpecializationButton: 'Edit',
    deleteSpecializationTitle: 'Delete specialization',
    deleteSpecializationButton: 'Delete',
    deleteSpecializationConfirm: 'Choose specialization which you want to delete.',
    specializationName: 'Name',
    newSpecializationName: 'New name',

    statisticsVacanciesTitle: 'Closing vacancies statistics',
    labelStartMonth: 'Select start month',
    labelEndMonth: 'Select end month',
    closedSuccessfullyField: 'Closed successfully',
    closedUnSuccessfullyField: 'Closed unsuccessfully',
    hiringAndFiringStatistics: 'Hiring and firing statistics',
    hiredEmpoleesLabel: 'Hired employees',
    firedEmpoleesLabel: 'Fired employees',
    dateOfHiring: 'Date of hiring',
    dateOfDismissal: 'Date of dismissal',
    statisticsFunnelOfCandidatesTitle: 'Funnel of candidates',
    vacancyStatusLabel: 'Vacancy status',
    vacancyLabel: 'Select vacancy',
    suitableStatusCandidate: 'Suitable',
    checkingEnglishStatus: 'Checking English',
    underLeadsConsideration: 'Leads consideration',

    hireCandidate: 'Hire a candidate',
    canditateEditStatus: 'Edit status',

    fireAnEmployee: 'Fire an employee',
    roleTitle: 'Choose role',
    fireEmployee: 'Are you sure you want to fire employee?',
    firedEmployeeSuccessMsg: 'Employee has been fired',
    hiredCandidateSuccessMsg: 'Candidate has been hired',
    deleteEmployeeConfirmation: 'Are you sure you want to delete this candidate from statistics?',
    deleteEmployeeSuccess: 'Canidate was deleted successfully',
    changeCandidateStatusSuccessMsg: 'Candidate status has been successfully updated',
    changeCandidateStatusErrorMsg: 'Candidate status updating failed',
    usersDoesNotExist: 'Users do not exist',

    documentNameCell: 'Document Name',
    documentTypeCell: 'Type',
    documentUploadedCell: 'Uploaded at',
    documentCreateSuccessMsg: 'Document saved successfully',
    documentUpdateSuccessMsg: 'Document successfully updated ',
    documentUpdateFailureMsg: 'Document saving failed',
    documentCreateFailureMsg: 'Document updating failed',
    deleteDocumentConfirm: 'Are you sure you want to delete this document?',
    deleteDocumentSuccess: 'Document has been successfully deleted',
    deleteDocumentFailure: 'Document deleting failure',
    documentMenuFileLabel: 'Add file',
    documentMenuLinkLabel: 'Add link',
    documentModalName: 'Name',
    documentModalDescription: 'Description',
    documentModalType: 'Type',
    documentModalVisibility: 'Visibility',
    documentModalLink: 'Link',
    documentModalAdd: 'Add document',
    documentModalEdit: 'Edit document',
    documentModalAddFile: 'Add file',
    documentModalAddLink: 'Add link',
    documentModalEditFile: 'Edit file',
    documentModalEditLink: 'Edit link',
    documentsDoesntExist: 'There are no documents',
    documentDescriptionCell: 'Description',
    editUserVacancyInfo:
      "It is not possible to change the vacancy of a candidate with the status 'not suitable' or 'done'",
    githookSecret: 'GitHook secret',
    githookEndpoint: 'GitHook endpoint',
    withoutSprint: 'Without sprint',
    withoutVersion: 'Without version',
    downloadingCandidates: 'Downloading candidates',
    passwordValidationError:
      'The password must include: an uppercase letter, a lowercase letter, a number and a special character. The minimum number of characters is 8',
    templateTaskLabel: 'Create as template',
    templateCard: 'This card — template',
    templateBtnCreate: 'Create as template',
    templateBtnEdit: 'Edit template',
    doNotHaveAccess: "You don't have access to this project",
  },
};
